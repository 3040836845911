import styled from "styled-components";

export const IndexWrapper = styled.div`
  background: #f9e6dc;
  height: 100vh;

  .container {
    height: 100%;
    overflow: auto;
    padding: 40px;
  }

  @media only screen and (max-width: 480px) {
    height: 100%;
  }
`;

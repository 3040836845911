const initState = {
  isLogin: localStorage.getItem("isLogin") === "true" ? true : false,
  userData: null,
};
export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLogin: action.isLogin,
        userData: action.userData,
      };

    case "LOGOUT":
      return {
        ...state,
        isLogin: action.isLogin,
        userData: action.userData,
      };
    case "UPDATEUSER":
      return {
        ...state,
        userData: action.userData,
      };
    case "EVENTDETAILS":
      return {
        ...state,
        event: action.event,
      };

    default:
      return state;
  }
}

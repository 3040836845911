import { message } from "antd";
import Api from "Api/Api";
import React, { useEffect, useState } from "react";
import StaticPageLayout from "./index";

export default function Privacy() {
  const [content, setcontent] = useState("");

  useEffect(() => {
    Api.post("/static/fetch", { title: "Privacy Policies" })
      .then((res) => {
        setcontent(res.data.data.content);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  }, []);

  return (
    <StaticPageLayout title="Privacy Policy">
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </StaticPageLayout>
  );
}

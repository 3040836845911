const authActions = {
  login: (data) => {
    return {
      type: "LOGIN",
      isLogin: true,
      userData: data,
    };
  },

  logout: () => {
    return {
      type: "LOGOUT",
      isLogin: false,
      userData: null,
    };
  },
  updateUserData: (data) => {
    return {
      type: "UPDATEUSER",
      userData: data,
    };
  },
  eventDetails: (data) => {
    return {
      type: "EVENTDETAILS",
      event: data,
    };
  },
};

export default authActions;

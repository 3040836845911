import React, { useEffect } from "react";
import background from "Assets/Images/background.png";
import Api from "Api/Api";
import { useParams } from "react-router-dom";

export default function Download() {
  let params = useParams();

  useEffect(() => {
    Api.post(`/event/download/data/${params.token}`).then((res) => {
      let link = document.createElement("a");
      link.href = res.data.data.linkToDownloadZip;
      link.download = res.data.data.linkToDownloadZip;
      link.click();
      setTimeout(() => {
        window.close();
      }, 1000);
    });
  }, []);

  return (
    <div
      style={{
        background: `url(${background})`,
        height: "100vh",
        backgroundRepeat: "round",
      }}
    ></div>
  );
}

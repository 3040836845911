import { useMediaQuery } from "react-responsive";

export const useDevice = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1440 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 820 });
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return {
    isBigScreen,
    isDesktopOrLaptop,
    isTabletOrMobile,
    isMobile,
  };
};

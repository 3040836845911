import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import createReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};
//const history = createBrowserHistory();
const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
});
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, createReducer());
const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);
store.subscribe(() => {
  localStorage.setItem("accessToken", store.getState().auth.accessToken);
  localStorage.setItem("isLogin", store.getState().auth.isLogin);
  // localStorage.setItem("name", store.getState().auth.name);
  // localStorage.setItem("email", store.getState().auth.email);
});

export { store, history, persistor };

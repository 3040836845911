import styled from "styled-components";
import { Card, DatePicker, Form, Input, Modal } from "antd";
import CountryPhoneInput from "antd-country-phone-input";

export const CommonCard = styled(Card)`
  width: ${(props) => (props.width ? `${props.width}px` : "450px")};
  max-height: 88vh;
  overflow-x: auto;
  border: 1px solid #fef5f5;
  border-radius: 40px;
  .ant-card-body {
    padding: 24px 48px;
  }
  ::-webkit-scrollbar {
    width: 0px;
    border-radius: 10px;
    background: #ea9365;
  }
  @media only screen and (max-width: 480px) {
    max-height: 80vh;
    width: ${(props) => (props.width ? `${props.width}px` : "350px")};
    .ant-card-body {
      padding: 12px 24px;
    }
  }
`;

export const Headerfont = styled.div`
  font-family: Roboto-Bold;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "18px")};
  color: #ea9365;
  line-height: 30px;
`;

export const Regularfont = styled.div`
  font-family: Roboto-Regular;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "14px")};
  color: ${(props) => (props.color ? `${props.color}` : "#ea9365")};
  line-height: 30px;
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #ea9365;
    font-family: Roboto-Regular;
  }
  margin-bottom: 12px;
`;

export const CustomInput = styled(Input)`
  background: #c4c4c4 !important;
`;

export const CustomTextArea = styled(Input.TextArea)`
  background: #c4c4c4 !important;
`;

export const CustomCountryInput = styled(CountryPhoneInput)`
  background: #c4c4c4 !important;
  .ant-input-affix-wrapper {
    background: #c4c4c4 !important;
  }
  .ant-input {
    background: #c4c4c4 !important;
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  background: #c4c4c4 !important;
  width: 100%;
`;

export const CustomInputPassword = styled(Input.Password)`
  background: #c4c4c4 !important;
  .ant-input-affix-wrapper {
    background: #c4c4c4 !important;
  }
  .ant-input {
    background: #c4c4c4 !important;
  }
`;

export const CommonButton = styled.button`
  border-radius: 20px;
  font-family: Roboto-Regular;
  background: ${(props) => (props.color ? `${props.color}` : "#ff7676")};
  border-color: ${(props) => (props.color ? `${props.color}` : "#ff7676")};
  padding: 8px 50px;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 13px;
  width: ${(props) => props.width && `${props.width}px`};
  @media only screen and (max-width: 480px) {
    padding: 8px 40px;
  }
`;

export const CommonAnchor = styled.a`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: #ea9365;
`;

export const CommonCardSmall = styled(Card)`
  width: ${(props) => (props.width ? `${props.width}%` : "200px")};
  border: ${(props) =>
      props.bordercolor
        ? `1px solid #${props.bordercolor}`
        : " 1px solid #fef5f5;"}
    1px solid #fef5f5;
  border-radius: 20px;
  .ant-card-body {
    padding: 20px;
  }
  cursor: pointer;
`;

export const CommonModal = styled(Modal)`
  border-radius: 40px !important;
  .ant-modal-content {
    max-height: 550px !important;
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 0px;
      border-radius: 10px;
      background: #ea9365;
    }
  }
`;

export const Drawer = styled(Modal)`
  .ant-modal,
  .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }
  .ant-modal-body {
    height: calc(100vh - 110px);
  }
`;

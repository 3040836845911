import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoutes, routes } from "./Routes/index";
import { Suspense } from "react";
import ErrorBoundary from "Errorboundary.js";
import { useSelector } from "react-redux";
import "./Helpers/helper.scss";
import "antd/dist/antd.min.css";
import "./App.css";
import ProtectedRoute from "Routes/ProtectedRoute";
import PageNotFound from "Routes/PageNotFound";

function App() {
  const data = useSelector((state) => state.auth);

  return (
    <div className="default">
      <ErrorBoundary>
        <BrowserRouter>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  background: "#fef5f5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            }
          >
            <Routes>
              {routes.map((route, i) => {
                return (
                  <Route path={route.path} element={route.component} key={i} />
                );
              })}
              <Route element={<ProtectedRoute login={data.isLogin} />}>
                {ProtectedRoutes.map((route, i) => {
                  return (
                    <Route
                      path={route.path}
                      element={route.component}
                      key={i}
                    />
                  );
                })}
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;

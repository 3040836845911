import axios from "axios";
import { message } from "antd";
import { store } from "redux/store";
import AuthActions from "redux/auth/actions";
const { logout } = AuthActions;

const Api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

Api.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem("isLogin") === "true") {
      config.headers = {
        Authorization: localStorage.getItem("Authorization"),
      };
    } else {
      config.headers = {};
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;

    if (
      error.response.data.message ===
      "Issues while Authorization, Token does not exist"
    )
      if (status === 401) {
        if (
          error.response.data.message ===
          "Issues while Authorization, Token does not exist"
        ) {
          axios.defaults.headers.common["Authorization"] =
            localStorage.getItem("Authorization");
          message.error("Session Expired");
          store.dispatch(logout());
        }
      }

    return Promise.reject(error);
  }
);

export default Api;

import React, { useEffect, useState } from "react";
import desktopbg from "Assets/Images/desktopbg.png";
import mobilebg from "Assets/Images/mobilebg.png";
import Api from "Api/Api";
import { useParams } from "react-router-dom";
import { useDevice } from "Helpers/useDevice";
import download from "Assets/Images/download.svg";
import { message, Spin } from "antd";

export default function Media() {
  let params = useParams();
  const { isMobile } = useDevice();
  const [media, setMedia] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Api.get(`/event/media/page/${params.token}`).then((res) => {
      setMedia(res.data.data);
    });
  }, [params]);

  const onDownload = () => {
    try {
      setLoading(true);
      Api.post(
        "event/download/media",
        { mediaId: media.id },
        { responseType: "blob" }
      )
        .then((response) => {
          const name = media?.mediaOriginal.split("/");
          const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name[name.length - 1]); //this is the name with which the file will be downloaded
          link.click();
          setLoading(false);
        })
        .catch((err) => {
          message.error("Download Failed!");
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      message.error("Download Failed!");
    }
  };

  return (
    <div
      style={{
        background: `url(${isMobile ? mobilebg : desktopbg})`,
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-y justify-center align-center full-height">
        {media?.mediaType === "VIDEO" ||
        media?.mediaType === "BOOMERANG" ||
        media?.mediaType === "GIF" ? (
          <video
            autoPlay
            muted
            height={600}
            width={isMobile && 350}
            loop
            playsInline
          >
            <source src={media?.mediaOriginal} type="video/mp4" />
          </video>
        ) : (
          <img
            src={media?.mediaOriginal}
            alt="photoDetail"
            height={600}
            width={isMobile && 350}
            style={{ objectFit: "contain" }}
          />
        )}
        {!loading && (
          <div className="mtb-24 h-32 w-32" role="button" onClick={onDownload}>
            <img
              src={download}
              height="100%"
              width="100%"
              className="cursor-pointer"
              alt="download"
            />
          </div>
        )}
        {!!loading && (
          <div className="mtb-24 h-32 flex justify-center align-center">
            <div className="flex justify-center align-center">
              <Spin />
              <div className="pl-8">Downloading...</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import Download from "Components/StaticPages/Download";
import Media from "Components/StaticPages/Media";
import Privacy from "Components/StaticPages/Privacy";
import Terms from "Components/StaticPages/Terms";
import React, { lazy } from "react";
const LoginFlow = lazy(() => import("../Components/LoginFlow/index"));
const DashboardFlow = lazy(() => import("../Components/DashboardFlow/index"));

export const routes = [
  {
    path: "/",
    component: <LoginFlow />,
  },
  {
    path: "/register",
    component: <LoginFlow />,
  },
  {
    path: "/create-password",
    component: <LoginFlow />,
  },
  {
    path: "/forgot-password",
    component: <LoginFlow />,
  },
  {
    path: "/reset-password/:token",
    component: <LoginFlow />,
  },
  {
    path: "/terms",
    component: <Terms />,
  },
  {
    path: "/privacy-policy",
    component: <Privacy />,
  },
  {
    path: "/download/:token",
    component: <Download />,
  },
  {
    path: "/media/:token",
    component: <Media />,
  },
];

export const ProtectedRoutes = [
  {
    path: "/your-events",
    component: <DashboardFlow />,
  },
  {
    path: "/event-details",
    component: <DashboardFlow />,
  },
  {
    path: "/event-settings",
    component: <DashboardFlow />,
  },
  {
    path: "/event-setting",
    component: <DashboardFlow />,
  },
  {
    path: "/user",
    component: <DashboardFlow />,
  },
];

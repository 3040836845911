import { IndexWrapper } from "./main.style";
import React from "react";
import { Headerfont, Regularfont } from "Styles/common.style";

export default function StaticPageLayout(props) {
  const { title, children } = props;

  return (
    <IndexWrapper>
      <div className="container">
        <Headerfont>{title}</Headerfont>
        <div className="pt-20">
          <Regularfont color="black">{children}</Regularfont>
        </div>
      </div>
    </IndexWrapper>
  );
}
